<template>
  <div class="specimen-detail-container">
    <!-- 编辑 -->
    <el-button type="primary" style="margin-bottom:15px;" @click="editSpecimenDetail" v-if="!isAuth('2')">编辑</el-button>
    <!-- 人类信息 如果specimenHumanDetail.type类型是human就显示 -->
    <div v-loading="dataListLoading" class="specimen-detail-table-content" v-if="specimenHumanDetail.type==='human'">
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">盒子编号</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.code }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">盒子在冰箱位置</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.coordinate }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">疾病</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.disease }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">住院号</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.inpatientNumber }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">姓名</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.name }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">出生日期</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.birthday }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">入院日期</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.admissionDate }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">出院日期</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.dischargeDate }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">取材时间</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.samplingDate }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">分装管数</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.fznumber }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">入院诊断</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.admissionDiagnosis }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">出院诊断</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.dischargeDiagnosis }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">年龄</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.age }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">性别</div>
        <!-- 如果为null就不显示数值 -->
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.gender === null ? '' : (specimenHumanDetail.gender == 1 ? '男' : '女') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">身高(cm)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.height }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">体重(kg)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.weight }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">收缩压SBP(mmhg)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.sbp }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">舒张压DBP(mmhg)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.dbp }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">心率HR(bpm)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.hr }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">高血压</div>
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.hypertension === null ? '' : (specimenHumanDetail.hypertension == 1 ? '有' : '无') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">高血脂</div>
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.hyperlipidemia === null ? '' : (specimenHumanDetail.hyperlipidemia == 1 ? '有' : '无') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">糖尿病</div>
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.diabetes === null ? '' : (specimenHumanDetail.diabetes == 1 ? '有' : '无') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">降压药</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.jyy }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">降糖药</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.jty }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">冠心病</div>
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.gxb === null ? '' : (specimenHumanDetail.gxb == 1 ? '有' : '无') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">房颤</div>
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.fc === null ? '' : (specimenHumanDetail.fc == 1 ? '有' : '无') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">慢性心力衰竭</div>
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.xlsj === null ? '' : (specimenHumanDetail.xlsj == 1 ? '有' : '无') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">PCI</div>
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.pci === null ? '' : (specimenHumanDetail.pci == 1 ? '有' : '无') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">室间隔消融术</div>
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.sjg === null ? '' : (specimenHumanDetail.sjg == 1 ? '有' : '无') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">胸闷</div>
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.xm === null ? '' : (specimenHumanDetail.xm == 1 ? '有' : '无') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">吸烟</div>
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.smoking === null ? '' : (specimenHumanDetail.smoking == 1 ? '是' : '否') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">饮酒</div>
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.drinking === null ? '' : (specimenHumanDetail.drinking == 1 ? '是' : '否') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">家族史</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.jzs }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">心超提示</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.xcts }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">主动脉根部内径(mm)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.zdm }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">左房内径(mm)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.zfnj }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">左室舒张末内径(mm)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.zssz }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">左室收缩末内径(mm)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.zsss }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">室间隔厚度(mm)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.sjghd }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">左室后壁厚度(mm)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.zshb }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">肺动脉收缩压(mmHg)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.pasp }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">DTI示S峰波峰值(cm/s)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.bti }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">EA是否双峰</div>
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.ea === null ? '' : (specimenHumanDetail.ea == 1 ? '是' : '否') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">e'/a'</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.ea2 }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">E/A</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.ea1 }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">DT(ms)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.dt }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">LVEF</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.lvef }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">主动脉瓣有无钙化</div>
        <div class="specimen-detail-table-col">
          {{ specimenHumanDetail.zdmb === null ? '' : (specimenHumanDetail.zdmb == 1 ? '有' : '无') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">三尖瓣环收缩期位移</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.sjbh }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">白细胞计数(WBC*10^9/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.bxbjs }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">中性粒细胞比例(Neu%)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.zxlxb }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">淋巴细胞比例(Lym%)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.lbxb }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">血红蛋白(Hb g/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.xhdb }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">高敏感C反应蛋白(mg/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.gmgc }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">肌酸激酶同工酶(CK-MB U/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.jsjm }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">尿素(mmol/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.ns }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">肌酐(umol/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.jg }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">尿酸(umol/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.nsuan }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">葡萄糖(mmol/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.ptt }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">糖化血红蛋白(%)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.thxhdb }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">总胆固醇(mmol/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.zdgc }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">甘油三酯(mmol/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.gysz }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">低密度脂蛋白胆固醇(mmol/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.dmdz }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">非高密度脂蛋白胆固醇(mmol/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.fgmdzdb }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">高密度脂蛋白胆固醇(mmol/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.gmdzdb }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">载脂蛋白B(g/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.zdbb }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">脂蛋白a(nmol/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.dba }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">丙氨酸转氨酶(ALT U/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.bas }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">天冬氨酸转氨酶(AST U/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.tdas }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">肾小球滤过率(ml/min/1.73m2)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.sxq }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">心肌肌钙蛋白(cTNTng/ml)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.xjjg }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">氨基末端利钠肽前体(pro-BNP pg/ml)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.ajmd }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">乳酸脱氢酶(LDH U/L)</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.ldh }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">标本类型</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.sampleType===null?'':(specimenHumanDetail.sampleType.trim() ==='blood'?'血':(specimenHumanDetail.sampleType.trim() ==='sample'?'标本':'其他')) }}</div>
      </div>
      <div class="specimen-detail-table-row description-row">
        <div class="specimen-detail-table-col-title">备注栏</div>
        <div class="specimen-detail-table-col">{{ specimenHumanDetail.description }}</div>
      </div>
    </div>
    <!-- 人类表单 -->
    <div class="edit-dialog" v-if="specimenHumanDetail.type==='human'">
      <el-dialog title="编辑标本详情" :visible.sync="dialogVisible" width="700px" center>
        <el-form :model="specimenHumanDetailForm" ref="form" label-width="250px" v-loading="editFormLoading" :rules="humanRules">
          <el-form-item label="盒子编号" prop="code">
            <el-input v-model="specimenHumanDetailForm.code"></el-input>
          </el-form-item>
          <el-form-item label="盒子在冰箱位置" prop="coordinate">
            <el-input v-model="specimenHumanDetailForm.coordinate"></el-input>
          </el-form-item>
          <el-form-item label="疾病" prop="disease">
            <el-input v-model="specimenHumanDetailForm.disease"></el-input>
          </el-form-item>
          <el-form-item label="住院号" prop="inpatientNumber">
            <el-input v-model="specimenHumanDetailForm.inpatientNumber"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="specimenHumanDetailForm.name"></el-input>
          </el-form-item>
          <el-form-item label="出生日期" prop="birthday">
            <el-date-picker v-model="specimenHumanDetailForm.birthday" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="入院日期" prop="admissionDate">
            <el-date-picker v-model="specimenHumanDetailForm.admissionDate" type="datetime" placeholder="选择日期时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="出院日期" prop="dischargeDate">
            <el-date-picker v-model="specimenHumanDetailForm.dischargeDate" type="datetime" placeholder="选择日期时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="取材时间" prop="samplingDate">
            <el-date-picker v-model="specimenHumanDetailForm.samplingDate" type="datetime" placeholder="选择日期时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="分装管数" prop="fznumber">
            <el-input v-model.number="specimenHumanDetailForm.fznumber"></el-input>
          </el-form-item>
          <el-form-item label="入院诊断" prop="admissionDiagnosis">
            <el-input v-model="specimenHumanDetailForm.admissionDiagnosis"></el-input>
          </el-form-item>
          <el-form-item label="出院诊断" prop="dischargeDiagnosis">
            <el-input v-model="specimenHumanDetailForm.dischargeDiagnosis"></el-input>
          </el-form-item>
          <el-form-item label="年龄" prop="age">
            <el-input v-model.number="specimenHumanDetailForm.age"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <el-radio-group v-model="specimenHumanDetailForm.gender">
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="身高(cm)" prop="height">
            <el-input v-model="specimenHumanDetailForm.height"></el-input>
          </el-form-item>
          <el-form-item label="体重(kg)" prop="weight">
            <el-input v-model="specimenHumanDetailForm.weight"></el-input>
          </el-form-item>
          <el-form-item label="收缩压SBP(mmhg)" prop="sbp">
            <el-input v-model="specimenHumanDetailForm.sbp"></el-input>
          </el-form-item>
          <el-form-item label="舒张压DBP(mmhg)" prop="dbp">
            <el-input v-model="specimenHumanDetailForm.dbp"></el-input>
          </el-form-item>
          <el-form-item label="心率HR(bpm)" prop="hr">
            <el-input v-model="specimenHumanDetailForm.hr"></el-input>
          </el-form-item>
          <el-form-item label="高血压" prop="hypertension">
            <el-radio-group v-model="specimenHumanDetailForm.hypertension">
              <el-radio label="1">有</el-radio>
              <el-radio label="0">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="高血脂" prop="hyperlipidemia">
            <el-radio-group v-model="specimenHumanDetailForm.hyperlipidemia">
              <el-radio label="1">有</el-radio>
              <el-radio label="0">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="糖尿病" prop="diabetes">
            <el-radio-group v-model="specimenHumanDetailForm.diabetes">
              <el-radio label="1">有</el-radio>
              <el-radio label="0">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="降压药" prop="jyy">
            <el-input v-model="specimenHumanDetailForm.jyy"></el-input>
          </el-form-item>
          <el-form-item label="降糖药" prop="jty">
            <el-input v-model="specimenHumanDetailForm.jty"></el-input>
          </el-form-item>
          <el-form-item label="冠心病" prop="gxb">
            <el-radio-group v-model="specimenHumanDetailForm.gxb">
              <el-radio label="1">有</el-radio>
              <el-radio label="0">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="房颤" prop="fc">
            <el-radio-group v-model="specimenHumanDetailForm.fc">
              <el-radio label="1">有</el-radio>
              <el-radio label="0">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="慢性心力衰竭" prop="xlsj">
            <el-radio-group v-model="specimenHumanDetailForm.xlsj">
              <el-radio label="1">有</el-radio>
              <el-radio label="0">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="PCI" prop="pci">
            <el-radio-group v-model="specimenHumanDetailForm.pci">
              <el-radio label="1">有</el-radio>
              <el-radio label="0">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="室间隔消融术" prop="sjg">
            <el-radio-group v-model="specimenHumanDetailForm.sjg">
              <el-radio label="1">有</el-radio>
              <el-radio label="0">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="胸闷" prop="xm">
            <el-radio-group v-model="specimenHumanDetailForm.xm">
              <el-radio label="1">有</el-radio>
              <el-radio label="0">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="吸烟" prop="smoking">
            <el-radio-group v-model="specimenHumanDetailForm.smoking">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="饮酒" prop="drinking">
            <el-radio-group v-model="specimenHumanDetailForm.drinking">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="家族史" prop="jzs">
            <el-input v-model="specimenHumanDetailForm.jzs"></el-input>
          </el-form-item>
          <el-form-item label="心超提示" prop="xcts">
            <el-input v-model="specimenHumanDetailForm.xcts"></el-input>
          </el-form-item>
          <el-form-item label="主动脉根部内径(mm)" prop="zdm">
            <el-input v-model="specimenHumanDetailForm.zdm"></el-input>
          </el-form-item>
          <el-form-item label="左房内径(mm)" prop="zfnj">
            <el-input v-model="specimenHumanDetailForm.zfnj"></el-input>
          </el-form-item>
          <el-form-item label="左室舒张末内径(mm)" prop="zssz">
            <el-input v-model="specimenHumanDetailForm.zssz"></el-input>
          </el-form-item>
          <el-form-item label="左室收缩末内径(mm)" prop="zsss">
            <el-input v-model="specimenHumanDetailForm.zsss"></el-input>
          </el-form-item>
          <el-form-item label="室间隔厚度(mm)" prop="sjghd">
            <el-input v-model="specimenHumanDetailForm.sjghd"></el-input>
          </el-form-item>
          <el-form-item label="左室后壁厚度(mm)" prop="zshb">
            <el-input v-model="specimenHumanDetailForm.zshb"></el-input>
          </el-form-item>
          <el-form-item label="肺动脉收缩压(mmHg)" prop="pasp">
            <el-input v-model="specimenHumanDetailForm.pasp"></el-input>
          </el-form-item>
          <el-form-item label="DTI示S峰波峰值(cm/s)" prop="bti">
            <el-input v-model="specimenHumanDetailForm.bti"></el-input>
          </el-form-item>
          <el-form-item label="EA是否双峰" prop="ea">
            <el-radio-group v-model="specimenHumanDetailForm.ea">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="e'/a'" prop="ea2">
            <el-input v-model="specimenHumanDetailForm.ea2"></el-input>
          </el-form-item>
          <el-form-item label="E/A" prop="ea1">
            <el-input v-model="specimenHumanDetailForm.ea1"></el-input>
          </el-form-item>
          <el-form-item label="DT(ms)" prop="dt">
            <el-input v-model="specimenHumanDetailForm.dt"></el-input>
          </el-form-item>
          <el-form-item label="LVEF" prop="lvef">
            <el-input v-model="specimenHumanDetailForm.lvef"></el-input>
          </el-form-item>
          <el-form-item label="主动脉瓣有无钙化" prop="zdmb">
            <el-radio-group v-model="specimenHumanDetailForm.zdmb">
              <el-radio label="1">有</el-radio>
              <el-radio label="0">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="三尖瓣环收缩期位移" prop="sjbh">
            <el-input v-model="specimenHumanDetailForm.sjbh"></el-input>
          </el-form-item>
          <el-form-item label="白细胞计数(WBC*10^9/L)" prop="bxbjs">
            <el-input v-model="specimenHumanDetailForm.bxbjs"></el-input>
          </el-form-item>
          <el-form-item label="中性粒细胞比例(Neu%)" prop="zxlxb">
            <el-input v-model="specimenHumanDetailForm.zxlxb"></el-input>
          </el-form-item>
          <el-form-item label="淋巴细胞比例(Lym%)" prop="lbxb">
            <el-input v-model="specimenHumanDetailForm.lbxb"></el-input>
          </el-form-item>
          <el-form-item label="血红蛋白(Hb g/L)" prop="xhdb">
            <el-input v-model="specimenHumanDetailForm.xhdb"></el-input>
          </el-form-item>
          <el-form-item label="高敏感C反应蛋白(mg/L)" prop="gmgc">
            <el-input v-model="specimenHumanDetailForm.gmgc"></el-input>
          </el-form-item>
          <el-form-item label="肌酸激酶同工酶(CK-MB U/L)" prop="jsjm">
            <el-input v-model="specimenHumanDetailForm.jsjm"></el-input>
          </el-form-item>
          <el-form-item label="尿素(mmol/L)" prop="ns">
            <el-input v-model="specimenHumanDetailForm.ns"></el-input>
          </el-form-item>
          <el-form-item label="肌酐(umol/L)" prop="jg">
            <el-input v-model="specimenHumanDetailForm.jg"></el-input>
          </el-form-item>
          <el-form-item label="尿酸(umol/L)" prop="nsuan">
            <el-input v-model="specimenHumanDetailForm.nsuan"></el-input>
          </el-form-item>
          <el-form-item label="葡萄糖(mmol/L)" prop="ptt">
            <el-input v-model="specimenHumanDetailForm.ptt"></el-input>
          </el-form-item>
          <el-form-item label="糖化血红蛋白(%)" prop="thxhdb">
            <el-input v-model="specimenHumanDetailForm.thxhdb"></el-input>
          </el-form-item>
          <el-form-item label="总胆固醇(mmol/L)" prop="zdgc">
            <el-input v-model="specimenHumanDetailForm.zdgc"></el-input>
          </el-form-item>
          <el-form-item label="甘油三酯(mmol/L)" prop="gysz">
            <el-input v-model="specimenHumanDetailForm.gysz"></el-input>
          </el-form-item>
          <el-form-item label="低密度脂蛋白胆固醇(mmol/L)" prop="dmdz">
            <el-input v-model="specimenHumanDetailForm.dmdz"></el-input>
          </el-form-item>
          <el-form-item label="非高密度脂蛋白胆固醇(mmol/L)" prop="fgmdzdb">
            <el-input v-model="specimenHumanDetailForm.fgmdzdb"></el-input>
          </el-form-item>
          <el-form-item label="高密度脂蛋白胆固醇(mmol/L)" prop="gmdzdb">
            <el-input v-model="specimenHumanDetailForm.gmdzdb"></el-input>
          </el-form-item>
          <el-form-item label="载脂蛋白B(g/L)" prop="zdbb">
            <el-input v-model="specimenHumanDetailForm.zdbb"></el-input>
          </el-form-item>
          <el-form-item label="脂蛋白a(nmol/L)" prop="dba">
            <el-input v-model="specimenHumanDetailForm.dba"></el-input>
          </el-form-item>
          <el-form-item label="丙氨酸转氨酶(ALT U/L)" prop="bas">
            <el-input v-model="specimenHumanDetailForm.bas"></el-input>
          </el-form-item>
          <el-form-item label="天冬氨酸转氨酶(AST U/L)" prop="tdas">
            <el-input v-model="specimenHumanDetailForm.tdas"></el-input>
          </el-form-item>
          <el-form-item label="肾小球滤过率(ml/min/1.73m2)" prop="sxq">
            <el-input v-model="specimenHumanDetailForm.sxq"></el-input>
          </el-form-item>
          <el-form-item label="心肌肌钙蛋白(cTNTng/ml)" prop="xjjg">
            <el-input v-model="specimenHumanDetailForm.xjjg"></el-input>
          </el-form-item>
          <el-form-item label="氨基末端利钠肽前体(pro-BNP pg/ml)" prop="ajmd">
            <el-input v-model="specimenHumanDetailForm.ajmd"></el-input>
          </el-form-item>
          <el-form-item label="乳酸脱氢酶(LDH U/L)" prop="ldh">
            <el-input v-model="specimenHumanDetailForm.ldh"></el-input>
          </el-form-item>
          <el-form-item label="标本类型" prop="sampleType">
            <el-radio-group v-model="specimenHumanDetailForm.sampleType">
              <el-radio label="blood">血</el-radio>
              <el-radio label="sample">标本</el-radio>
              <el-radio label="other">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注栏" prop="description">
            <el-input v-model="specimenHumanDetailForm.description"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitSpecimenDetail" :disabled="editFormLoading">保存</el-button>
            <el-button @click="dialogVisible = false" :disabled="editFormLoading">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <!-- 监测日期 小鼠耳标	小鼠脚标 基因类型	干预组别 性别 HW BW TL LV-HR(BPM)	LV-Diameter;s(mm) LV-Diameter;d(mm)	LV-Volume;s(ul)	 LV-Volume;d(ul)	LV-SV	LV-EF	LV-FS	LV-CO 		
      RV-HR	 RV-D;s(mm)	 RV-D;d(mm) 	RV-V;s(ul) 	RV-V;d(ul)	RV-SV	RV-EF	RV-FS	RV-CO(ML/min)	RV-R;s	RV-L;s	RV-RVSd横径	RV-RVSd基底段	RV-RVDd横径	RV-RVDd基底段	RV-R;d	RV-L;d	RV-TAPSE(mm）
      ELISA-1	ELISA-2	ELISA-3	ELISA-4
      一共40个字段 -->

    <!-- 小鼠信息 如果specimenMouseDetail.type类型是mouse就显示 -->
    <div v-loading="dataListLoading" class="specimen-detail-table-content" v-if="specimenMouseDetail.type==='mouse'">
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">盒子编号</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.code }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">监测日期</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.monitoringDate }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">小鼠耳标</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.earTag }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">小鼠脚标</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.footTag }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">基因类型</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.geneType }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">干预组别</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.interventionGroup }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">性别</div>
        <div class="specimen-detail-table-col">
          {{ specimenMouseDetail.gender === null ? '' : (specimenMouseDetail.gender == 1 ? '雄' : '雌') }}
        </div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">HW</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.hw }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">BW</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.bw }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">TL</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.tl }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">LV-HR(BPM)</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.lvHr }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">LV-Diameter;s(mm)</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.lvDiameterS }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">LV-Diameter;d(mm)</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.lvDiameterD }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">LV-Volume;s(ul)</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.lvVolumeS }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">LV-Volume;d(ul)</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.lvVolumeD }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">LV-SV</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.lvSv }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">LV-EF</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.lvEf }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">LV-FS</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.lvFs }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">LV-CO</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.lvCo }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-HR</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvHr }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-D;s(mm)</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvDS }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-D;d(mm)</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvDD }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-V;s(ul)</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvVS }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-V;d(ul)</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvVD }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-SV</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvSv }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-EF</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvEf }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-FS</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvFs }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-CO(ML/min)</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvCo }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-R;s</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvRS }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-L;s</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvLS }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-RVSd横径</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvRvsdh }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-RVSd基底段</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvRvsdj }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-RVDd横径</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvRvddh }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-RVDd基底段</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvRvddj }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-R;d</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvRD }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-L;d</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvLD }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">RV-TAPSE(mm)</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.rvTapse }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">ELISA-1</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.elisa1 }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">ELISA-2</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.elisa2 }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">ELISA-3</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.elisa3 }}</div>
      </div>
      <div class="specimen-detail-table-row">
        <div class="specimen-detail-table-col-title">ELISA-4</div>
        <div class="specimen-detail-table-col">{{ specimenMouseDetail.elisa4 }}</div>
      </div>
    </div>

    <!-- 小鼠表单 -->
    <div class="edit-dialog" v-if="specimenMouseDetail.type==='mouse'">
      <el-dialog title="编辑标本详情" :visible.sync="dialogVisible" width="500px" center>
        <el-form :model="specimenMouseDetailForm" ref="form" label-width="150px" v-loading="editFormLoading" :rules="mouseRules">
          <el-form-item label="盒子编号" prop="code">
            <el-input v-model="specimenMouseDetailForm.code"></el-input>
          </el-form-item>
          <el-form-item label="监测日期" prop="monitoringDate">
            <el-date-picker v-model="specimenMouseDetailForm.monitoringDate" type="datetime" placeholder="选择日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="小鼠耳标" prop="earTag">
            <el-input v-model="specimenMouseDetailForm.earTag"></el-input>
          </el-form-item>
          <el-form-item label="小鼠脚标" prop="footTag">
            <el-input v-model="specimenMouseDetailForm.footTag"></el-input>
          </el-form-item>
          <el-form-item label="基因类型" prop="geneType">
            <el-input v-model="specimenMouseDetailForm.geneType"></el-input>
          </el-form-item>
          <el-form-item label="干预组别" prop="interventionGroup">
            <el-input v-model="specimenMouseDetailForm.interventionGroup"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <el-radio-group v-model="specimenMouseDetailForm.gender">
              <el-radio label="1">雄</el-radio>
              <el-radio label="2">雌</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="HW" prop="hw">
            <el-input v-model="specimenMouseDetailForm.hw"></el-input>
          </el-form-item>
          <el-form-item label="BW" prop="bw">
            <el-input v-model="specimenMouseDetailForm.bw"></el-input>
          </el-form-item>
          <el-form-item label="TL" prop="tl">
            <el-input v-model="specimenMouseDetailForm.tl"></el-input>
          </el-form-item>
          <el-form-item label="LV-HR(BPM)" prop="lvHr">
            <el-input v-model="specimenMouseDetailForm.lvHr"></el-input>
          </el-form-item>
          <el-form-item label="LV-Diameter;s(mm)" prop="lvDiameterS">
            <el-input v-model="specimenMouseDetailForm.lvDiameterS"></el-input>
          </el-form-item>
          <el-form-item label="LV-Diameter;d(mm)" prop="lvDiameterD">
            <el-input v-model="specimenMouseDetailForm.lvDiameterD"></el-input>
          </el-form-item>
          <el-form-item label="LV-Volume;s(ul)" prop="lvVolumeS">
            <el-input v-model="specimenMouseDetailForm.lvVolumeS"></el-input>
          </el-form-item>
          <el-form-item label="LV-Volume;d(ul)" prop="lvVolumeD">
            <el-input v-model="specimenMouseDetailForm.lvVolumeD"></el-input>
          </el-form-item>
          <el-form-item label="LV-SV" prop="lvSv">
            <el-input v-model="specimenMouseDetailForm.lvSv"></el-input>
          </el-form-item>
          <el-form-item label="LV-EF" prop="lvEf">
            <el-input v-model="specimenMouseDetailForm.lvEf"></el-input>
          </el-form-item>
          <el-form-item label="LV-FS" prop="lvFs">
            <el-input v-model="specimenMouseDetailForm.lvFs"></el-input>
          </el-form-item>
          <el-form-item label="LV-CO" prop="lvCo">
            <el-input v-model="specimenMouseDetailForm.lvCo"></el-input>
          </el-form-item>
          <el-form-item label="RV-HR" prop="rvHr">
            <el-input v-model="specimenMouseDetailForm.rvHr"></el-input>
          </el-form-item>
          <el-form-item label="RV-D;s(mm)" prop="rvDS">
            <el-input v-model="specimenMouseDetailForm.rvDS"></el-input>
          </el-form-item>
          <el-form-item label="RV-D;d(mm)" prop="rvDD">
            <el-input v-model="specimenMouseDetailForm.rvDD"></el-input>
          </el-form-item>
          <el-form-item label="RV-V;s(ul)" prop="rvVS">
            <el-input v-model="specimenMouseDetailForm.rvVS"></el-input>
          </el-form-item>
          <el-form-item label="RV-V;d(ul)" prop="rvVD">
            <el-input v-model="specimenMouseDetailForm.rvVD"></el-input>
          </el-form-item>
          <el-form-item label="RV-SV" prop="rvSv">
            <el-input v-model="specimenMouseDetailForm.rvSv"></el-input>
          </el-form-item>
          <el-form-item label="RV-EF" prop="rvEf">
            <el-input v-model="specimenMouseDetailForm.rvEf"></el-input>
          </el-form-item>
          <el-form-item label="RV-FS" prop="rvFs">
            <el-input v-model="specimenMouseDetailForm.rvFs"></el-input>
          </el-form-item>
          <el-form-item label="RV-CO(ML/min)" prop="rvCo">
            <el-input v-model="specimenMouseDetailForm.rvCo"></el-input>
          </el-form-item>
          <el-form-item label="RV-R;s" prop="rvRS">
            <el-input v-model="specimenMouseDetailForm.rvRS"></el-input>
          </el-form-item>
          <el-form-item label="RV-L;s" prop="rvLS">
            <el-input v-model="specimenMouseDetailForm.rvLS"></el-input>
          </el-form-item>
          <el-form-item label="RV-RVSd横径" prop="rvRvsdh">
            <el-input v-model="specimenMouseDetailForm.rvRvsdh"></el-input>
          </el-form-item>
          <el-form-item label="RV-RVSd基底段" prop="rvRvsdj">
            <el-input v-model="specimenMouseDetailForm.rvRvsdj"></el-input>
          </el-form-item>
          <el-form-item label="RV-RVDd横径" prop="rvRvddh">
            <el-input v-model="specimenMouseDetailForm.rvRvddh"></el-input>
          </el-form-item>
          <el-form-item label="RV-RVDd基底段" prop="rvRvddj">
            <el-input v-model="specimenMouseDetailForm.rvRvddj"></el-input>
          </el-form-item>
          <el-form-item label="RV-R;d" prop="rvRD">
            <el-input v-model="specimenMouseDetailForm.rvRD"></el-input>
          </el-form-item>
          <el-form-item label="RV-L;d" prop="rvLD">
            <el-input v-model="specimenMouseDetailForm.rvLD"></el-input>
          </el-form-item>
          <el-form-item label="RV-TAPSE(mm)" prop="rvTapse">
            <el-input v-model="specimenMouseDetailForm.rvTapse"></el-input>
          </el-form-item>
          <el-form-item label="ELISA-1" prop="elisa1">
            <el-input v-model="specimenMouseDetailForm.elisa1"></el-input>
          </el-form-item>
          <el-form-item label="ELISA-2" prop="elisa2">
            <el-input v-model="specimenMouseDetailForm.elisa2"></el-input>
          </el-form-item>
          <el-form-item label="ELISA-3" prop="elisa3">
            <el-input v-model="specimenMouseDetailForm.elisa3"></el-input>
          </el-form-item>
          <el-form-item label="ELISA-4" prop="elisa4">
            <el-input v-model="specimenMouseDetailForm.elisa4"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitSpecimenDetail" :disabled="editFormLoading">保存</el-button>
            <el-button @click="dialogVisible = false" :disabled="editFormLoading">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import { getSpecimenListByBoxId, UpdateSpecimen, UpdateMouse } from '@/api/specimen'
export default {
  data() {
    return {
      specimenHumanDetail: {
        "boxId": "iR", //boxId
        "code": "",//盒子编号
        "refrigeratorId": "RID:240921050792144", //冰箱ID
        "colour": "1", //颜色
        "row": "3", //所属行
        "line": "1", //所属列
        "name": "修改", //姓名
        "type": "", //样本类型(人类:human)
        "inpatientNumber": "", //住院号
        "admissionDate": "2024-09-21T17:24:40", //入院日期
        "dischargeDate": null, //出院日期
        "samplingDate": "2024-09-20T17:24:36", //取材时间
        "operator": "1", //使用人
        "purpose": "1", //用途
        "useDate": "2024-09-21T17:25:14", //使用日期
        "remark": "1", //其他备注
        "fznumber": 1, //分装管数
        "admissionDiagnosis": "1", //入院诊断
        "dischargeDiagnosis": "1", //出院诊断
        "age": 1, //年龄
        "height": 1, //身高
        "weight": 1, //体重
        "sbp": "1", //收缩压
        "dbp": "1", //舒张压
        "hr": "1", //心率
        "hypertension": "1", //高血压(是1否0)
        "hyperlipidemia": "1", //高血脂(是1否0)
        "diabetes": "1", //糖尿病(是1否0)
        "jyy": "1", //降压药
        "jty": "1", //降糖药
        "fc": "1", //房颤(是1否0)
        "gxb": "1", //冠心病(是1否0)
        "xlsj": "1", //慢性心力衰竭有1无0
        "pci": "1", //pci(是1否0)
        "sjg": "1", //室间隔消融术有1无0
        "xm": "1", //胸闷(是1否0)
        "smoking": "1", //吸烟(是1否0)
        "drinking": "1", //喝酒(是1否0)
        "jzs": "1", //家族史
        "xcts": "1", //心超提示
        "zdm": "1", //主动脉根部内径(mm)
        "zfnj": "1", //左房内径
        "zssz": "1", //左室舒张末内径(mm)
        "zsss": "1", //左室收缩末内径(mm)
        "sjghd": "1", //室间隔厚度
        "zshb": "1", //左室后壁厚度
        "bti": "1", //DTI示S峰波峰值
        "ea": "1", //EA是否双峰(是1否0)
        "ea2": null, //e/a
        "dt": "1", //DT(ms)
        "lvef": "1", //LVEF
        "zdmb": "1", //主动脉瓣有无钙化(是1否0)
        "sjbh": "1", //三尖瓣环收缩期位移
        "bxbjs": "1", //白细胞计数
        "zxlxb": "1", //中性粒细胞比例
        "lbxb": "1", //淋巴细胞比率
        "xhdb": "1", //血红蛋白HB
        "gmgc": "1", //高敏感C反应蛋白
        "jsjm": "1", //肌酸激酶同工酶
        "ns": "1", //尿素
        "jg": "1", //肌酐
        "nsuan": "1", //尿酸
        "ptt": "1", //葡萄糖
        "thxhdb": "1", //糖化血红蛋白
        "zdgc": "1", //总胆固醇mmol/L
        "gysz": "1", //甘油三酯mmol
        "dmdz": "1", //低密度脂蛋白胆固醇mmol/L
        "fgmdzdb": "1", //非高密度脂蛋白胆固醇mmol/L
        "gmdzdb": "1", //高密度脂蛋白胆固醇mmol/L
        "zdbb": "1", //载脂蛋白B g/L
        "dba": "1", //脂蛋白a nmol/L
        "bas": "1", //丙氨酸转氨酶
        "tdas": "1", //天冬氨酸转氨酶
        "sxq": "1", //肾小球过滤率
        "xjjg": "1", //心肌肌钙蛋白
        "ajmd": "1", //氨基末端利钠肽前体
        "deleted": "1", //是否(1:未删除,-1:已删除)
        "ea1": null, //E'/A'
        "disease": "", // 疾病
        "birthday": "", // 出生日期
        "pasp": "", // 肺动脉收缩压(mmHg)
        "gender": "1", // 性别男1女2
        "coordinate": "", // 盒子在冰箱位置
        "ldh": "",//乳酸脱氢酶
        "description": "", // 备注
        "sampleType": "", // 样本类型(血blood 标本sample 其他other)

      },
      specimenHumanDetailForm: {
        "boxId": "",
        "code": "",
        "refrigeratorId": "", //冰箱ID
        "colour": "", //颜色
        "row": "", //所属行
        "line": "", //所属列
        "name": "", //姓名
        "type": "", //样本类型(人类:human)
        "inpatientNumber": "", //住院号
        "admissionDate": "", //入院日期
        "dischargeDate": null, //出院日期
        "samplingDate": "", //取材时间
        "operator": "", //使用人
        "purpose": "", //用途
        "useDate": "", //使用日期
        "remark": "", //其他备注
        "fznumber": null, //分装管数
        "admissionDiagnosis": "", //入院诊断
        "dischargeDiagnosis": "", //出院诊断
        "age": null, //年龄
        "height": null, //身高
        "weight": null, //体重
        "sbp": "", //收缩压
        "dbp": "", //舒张压
        "hr": "", //心率
        "hypertension": "0", //高血压(是1否0)
        "hyperlipidemia": "0", //高血脂(是1否0)
        "diabetes": "0", //糖尿病(是1否0)
        "jyy": "", //降压药
        "jty": "", //降糖药
        "fc": "0", //房颤(是1否0)
        "gxb": "0", //冠心病(是1否0)
        "xlsj": "0", //慢性心力衰竭有1无0
        "pci": "0", //pci(是1否0)
        "sjg": "0", //室间隔消融术有1无0
        "xm": "0", //胸闷(是1否0)
        "smoking": "0", //吸烟(是1否0)
        "drinking": "0", //喝酒(是1否0)
        "jzs": "", //家族史
        "xcts": "", //心超提示
        "zdm": "", //主动脉根部内径(mm)
        "zfnj": "", //左房内径
        "zssz": "", //左室舒张末内径(mm)
        "zsss": "", //左室收缩末内径(mm)
        "sjghd": "", //室间隔厚度
        "zshb": "", //左室后壁厚度
        "bti": "", //DTI示S峰波峰值
        "ea": "0", //EA是否双峰(是1否0)
        "ea2": null, //e/a
        "dt": "", //DT(ms)
        "lvef": "", //LVEF
        "zdmb": "0", //主动脉瓣有无钙化(是1否0)
        "sjbh": "", //三尖瓣环收缩期位移
        "bxbjs": "", //白细胞计数
        "zxlxb": "", //中性粒细胞比例
        "lbxb": "", //淋巴细胞比率
        "xhdb": "", //血红蛋白HB
        "gmgc": "", //高敏感C反应蛋白
        "jsjm": "", //肌酸激酶同工酶
        "ns": "", //尿素
        "jg": "", //肌酐
        "nsuan": "", //尿酸
        "ptt": "", //葡萄糖
        "thxhdb": "", //糖化血红蛋白
        "zdgc": "", //总胆固醇mmol/L
        "gysz": "", //甘油三酯mmol
        "dmdz": "", //低密度脂蛋白胆固醇mmol/L
        "fgmdzdb": "", //非高密度脂蛋白胆固醇mmol/L
        "gmdzdb": "", //高密度脂蛋白胆固醇mmol/L
        "zdbb": "", //载脂蛋白B g/L
        "dba": "", //脂蛋白a nmol/L
        "bas": "", //丙氨酸转氨酶
        "tdas": "", //天冬氨酸转氨酶
        "sxq": "", //肾小球过滤率
        "xjjg": "", //心肌肌钙蛋白
        "ajmd": "", //氨基末端利钠肽前体
        "deleted": "", //是否(1:未删除,-1:已删除)
        "ea1": null, //E'/A'
        "disease": "", // 疾病
        "birthday": "", // 出生日期
        "pasp": "", // 肺动脉收缩压(mmHg)
        "gender": "1", // 性别男1女2
        "coordinate": "", // 盒子在冰箱位置
        "ldh": "",//乳酸脱氢酶
        "description": "", // 备注
        "sampleType": "", // 标本类型
      },
      /**
      监测日期 小鼠耳标	小鼠脚标 基因类型	干预组别 性别 HW BW TL LV-HR(BPM)	LV-Diameter;s(mm) LV-Diameter;d(mm)	LV-Volume;s(ul)	LV-Volume;d(ul)	LV-SV	LV-EF	LV-FS	LV-CO 		
      RV-HR	RV-D;s(mm)	RV-D;d(mm)	RV-V;s(ul)	RV-V;d(ul)	RV-SV	RV-EF	RV-FS	RV-CO(ML/min)	RV-R;s	RV-L;s	RV-RVSd横径	RV-RVSd基底段	RV-RVDd横径	RV-RVDd基底段	RV-R;d	RV-L;d	RV-TAPSE(mm）
      ELISA-1	ELISA-2	ELISA-3	ELISA-4
      一共40个字段
       */
      specimenMouseDetail: {
        "boxId": "",
        "code": "",
        "type": "",
        "monitoringDate": null,
        "earTag": null,
        "footTag": null,
        "geneType": null,
        "interventionGroup": null,
        "gender": "1",
        "hw": null,
        "bw": null,
        "tl": null,
        "lvHr": null,
        "lvDiameterS": null,
        "lvDiameterD": null,
        "lvVolumeS": null,
        "lvVolumeD": null,
        "lvSv": null,
        "lvEf": null,
        "lvFs": null,
        "lvCo": null,
        "rvHr": null,
        "rvDS": null,
        "rvDD": null,
        "rvVS": null,
        "rvVD": null,
        "rvSv": null,
        "rvEf": null,
        "rvFs": null,
        "rvCo": null,
        "rvRS": null,
        "rvLS": null,
        "rvRvsdh": null,
        "rvRvsdj": null,
        "rvRvddh": null,
        "rvRvddj": null,
        "rvRD": null,
        "rvLD": null,
        "rvTapse": null,
        "elisa1": null,
        "elisa2": null,
        "elisa3": null,
        "elisa4": null
      },
      specimenMouseDetailForm: {
        "boxId": "",
        "code": "",
        "type": "",
        "monitoringDate": null,
        "earTag": null,
        "footTag": null,
        "geneType": null,
        "interventionGroup": null,
        "gender": "1",
        "hw": null,
        "bw": null,
        "tl": null,
        "lvHr": null,
        "lvDiameterS": null,
        "lvDiameterD": null,
        "lvVolumeS": null,
        "lvVolumeD": null,
        "lvSv": null,
        "lvEf": null,
        "lvFs": null,
        "lvCo": null,
        "rvHr": null,
        "rvDS": null,
        "rvDD": null,
        "rvVS": null,
        "rvVD": null,
        "rvSv": null,
        "rvEf": null,
        "rvFs": null,
        "rvCo": null,
        "rvRS": null,
        "rvLS": null,
        "rvRvsdh": null,
        "rvRvsdj": null,
        "rvRvddh": null,
        "rvRvddj": null,
        "rvRD": null,
        "rvLD": null,
        "rvTapse": null,
        "elisa1": null,
        "elisa2": null,
        "elisa3": null,
        "elisa4": null
      },
      dataListLoading: false,
      editFormLoading: false,
      dialogVisible: false,
      mouseRules: {
        earTag: [
          { required: true, message: '请输入小鼠耳标', trigger: 'blur' }
        ],
        footTag: [
          { required: true, message: '请输入小鼠脚标', trigger: 'blur' }
        ],
      },
      humanRules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        fznumber: [
          { required: true, message: '请输入分装管数', trigger: 'blur' },
          { type: 'number', message: '分装管数必须为数字' }
        ],
        age: [
          { type: 'number', message: '年龄必须为数字' }
        ],
        height: [
          { pattern: /^\d+(\.\d+)?$/, message: '身高必须为数字' }
        ],
        weight: [
          { pattern: /^\d+(\.\d+)?$/, message: '体重必须为数字' }
        ]
      }
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.dataListLoading = true
      getSpecimenListByBoxId(this.$route.query.bId).then((res) => {
        let resultData = res.data.humanVO === null ? res.data.mouseVO : res.data.humanVO
        resultData.type === 'human' ? this.specimenMouseDetail = {} : this.specimenHumanDetail = {}
        resultData.type === 'human' ? this.specimenHumanDetail = resultData : this.specimenMouseDetail = resultData

        this.dataListLoading = false
      })
    },
    // 格式化日期
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);

      // 获取年月日等信息
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要+1
      const day = String(date.getDate()).padStart(2, '0');


      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      // 拼接成所需格式
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },
    editSpecimenDetail() {
      this.dialogVisible = true
      this.editFormLoading = true
      // 加载数据
      getSpecimenListByBoxId(this.$route.query.bId).then((res) => {
        res.data.humanVO === null ? this.specimenMouseDetailForm = res.data.mouseVO : this.specimenHumanDetailForm = res.data.humanVO
        this.editFormLoading = false
      })
    },
    submitSpecimenDetail() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.editFormLoading = true
          // 格式化所有日期
          if (this.specimenHumanDetail.type === 'human') {
            let newBirthday = new Date(this.specimenHumanDetailForm.birthday)
            newBirthday = `${newBirthday.getFullYear()}-${String(newBirthday.getMonth() + 1).padStart(2, '0')}-${String(newBirthday.getDate()).padStart(2, '0')}`
            this.specimenHumanDetailForm.birthday = newBirthday
            this.specimenHumanDetailForm.admissionDate = this.formatDate(this.specimenHumanDetailForm.admissionDate)
            this.specimenHumanDetailForm.dischargeDate = this.formatDate(this.specimenHumanDetailForm.dischargeDate)
            this.specimenHumanDetailForm.samplingDate = this.formatDate(this.specimenHumanDetailForm.samplingDate)
            this.specimenHumanDetailForm.useDate = this.formatDate(this.specimenHumanDetailForm.useDate)

            UpdateSpecimen(this.specimenHumanDetailForm).then(() => {
              this.initData()
              this.$message.success('保存成功')
              this.editFormLoading = false
              this.dialogVisible = false
            }).catch(() => {
              this.editFormLoading = false
            })
            console.log("人", this.specimenHumanDetailForm)
          } else {
            // let newMonitoringDate = new Date(this.specimenMouseDetailForm.monitoringDate)
            // newMonitoringDate = `${newMonitoringDate.getFullYear()}-${String(newMonitoringDate.getMonth() + 1).padStart(2, '0')}-${String(newMonitoringDate.getDate()).padStart(2, '0')}`
            this.specimenMouseDetailForm.monitoringDate = this.formatDate(this.specimenMouseDetailForm.monitoringDate)
            UpdateMouse(this.specimenMouseDetailForm).then(() => {
              this.initData()
              this.$message.success('保存成功')
              this.editFormLoading = false
              this.dialogVisible = false
            }).catch(() => {
              this.editFormLoading = false
            })
            console.log("鼠", this.specimenMouseDetailForm)
          }
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.specimen-detail-container {
  width: 100%;
  height: 100%;
  padding: 10px 10px 20px 10px;
  .specimen-detail-table-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    .specimen-detail-table-row {
      width: calc(100% / 6);
      text-align: center;
      .specimen-detail-table-col {
        height: 120px;
        // 文字垂直居中
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.2px solid #e4e4e4;
        margin-bottom: 10px;
        padding: 10px;
      }
      .specimen-detail-table-col-title {
        height: 40px;
        line-height: 40px;
        border: 0.2px solid #e4e4e4;
        background: #f5f7fa;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    // 备注栏占满剩下的空间
    .description-row {
      width: calc(100% / 6 * 4);
    }
  }
}
</style>